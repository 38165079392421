html, body,
header,
footer,
.scroll-fix {
    min-width: 480px;
}

#wrapper {
    min-width: 525px;
}

.tooltip-inner {
    max-width: 340px;
}

.cursor-pointer {
    cursor: pointer;
}

table.table td > div[contenteditable="true"] {
    margin: -8px;
    padding: 8px;
}

.scroll-fix {
    position: relative;
}

#sidebar {
    padding: 0 0 60px 0;
    background: #18171b;
    float: left;
    margin-left: -15px;
    position: absolute;
    height: 100%;
    transition: width 0.3s ease-out 0s;
    z-index: 10;
}

#sidebar,
#sidebar li ul.sidebar-dropdown li,
#sidebar li ul.sidebar-dropdown {
    width: 300px;
    transition: margin-left 0.3s ease-out 0s;
}

#sidebar ul {
    padding: 0;
}


#sidebar li {
    list-style-type: none;
}


#sidebar li ul.sidebar-dropdown {
    display: none;
}

#sidebar li a {
    display: inline-block;
    width: 260px;
    color: #adadad;
    font-size: 13px;
    margin-left: 10px;
    height: 45px;
    white-space: nowrap;
    line-height: 45px;
}

#sidebar li.active > a {
    color: #fff;
}

#sidebar li a:hover {
    color: white;
}

#sidebar li ul.sidebar-dropdown li {
    padding-left: 20px;
}

#sidebar li.open .sidebar-dropdown {
    display: block;
}

#sidebar li a:hover,
#sidebar li a:focus {
    text-decoration: none;
}

#sidebar  li a i {
    font-size: 16px;
}

footer {
    padding: 15px;
    color: #444;
    background: #f1f1f1;
    border: 1px solid #d2d6de;
    margin-right: -15px;
    margin-left: -15px;
}

#content {
    margin-left: 300px;
    transition: margin-left 0.3s ease-out 0s;
}

.page-content {
    background: #f1f1f1;
    padding: 10px;
}

.page-content .island {
    background-color: #fff;
    padding: 10px;
    float: left;
}

.page-content .island.fix {
    min-height: 500px;
    min-width: 240px;
    margin-top: 10px;
    margin-right: 10px;
}

.page-content .island h3 {
    margin-top: 0;
}

.page-content .island h5 {
    font-size: 16px;
}

.page-content .island > .header,
.page-content .island > .alternate-header{
    margin: -10px;
    padding: 10px;
    border-top: 3px solid #777;
    border-bottom: 2px solid #f4f4f4;
}

.page-content .island > .header.success,
.page-content .island > .alternate-header.success{
    border-top: 3px solid #5cb85c;
}

.page-content .island > .header.info {
    border-top: 3px solid #5bc0de;
}

.page-content .island > .header.warning {
    border-top: 3px solid #f0ad4e;
}

.page-content .island > .header.danger {
    border-top: 3px solid #d9534f;
}

.page-content .island > .header .island-buttons {
    margin-top: -40px;
    color: #777;
}

.page-content .island .header .island-buttons button,
.page-content .island .header .island-buttons button:focus {
    border: none;
    outline: none;
}

.page-content .island .header .island-buttons button {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: transparent;
}

.page-content .island .content {
    margin-top: 15px!important;
}

.nav-tabs.top-menu {
    margin-bottom: 15px;
}

header {
    height: 50px;
    background: #18171b;
    margin-right: -15px;
    margin-left: -15px;
}

header a:focus,
header a:visited,
header a:active,
header a:hover,
header a,
header {
    color: #fff;
    text-decoration: none;
}

header .title {
    font-size: 18px;
}

header .logout,
header .title {
    line-height: 50px;
}

header .inline > div {
    display: inline;
    margin: 0 15px;
}

header .menu-collapse {
    float: left;
    margin-top: 15px;
    margin-right: 15px;
    background: none;
    border: none;
    outline: none;
}

#sidebar.collapse,
#sidebar.collapse li ul.sidebar-dropdown li,
#sidebar.collapse li ul.sidebar-dropdown {
    margin-left: -315px;
    display: block;
}

#sidebar.collapse ~#content {
    margin-left: 0;
}

@media all and (max-width: 618px) {
    header .inline > div {
        margin: 0 5px;
    }
}

@media all and (max-width: 1024px) {
    #block-content {
        padding-right: 0;
    }

    #sidebar,
    #sidebar li ul.sidebar-dropdown li,
    #sidebar li ul.sidebar-dropdown {
        margin-left: -315px;
        display: block;
    }

    #sidebar li a {
        text-indent: 100%;
        overflow: hidden;
    }

    #sidebar.collapse,
    #sidebar.collapse li ul.sidebar-dropdown li,
    #sidebar.collapse li ul.sidebar-dropdown {
        width: 300px;
        margin-left: -15px;
        display: block;
    }

    #sidebar.collapse li ul.sidebar-dropdown li a {
        margin-left: 40px;
    }

    #sidebar.collapse li a {
        text-indent: 0;
    }

    #sidebar li ul.sidebar-dropdown,
    #sidebar.collapse li ul.sidebar-dropdown {
        display: none;
    }

    #sidebar.collapse li.open .sidebar-dropdown {
        display: block;
    }

    #content {
        margin-left: 0;
    }
}

/* loader */
.cssload-container{
    position:relative;
    top: 50%;
}

.cssload-whirlpool,
.cssload-whirlpool::before,
.cssload-whirlpool::after {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid rgb(204,204,204);
    border-left-color: rgb(51,122,183);
    border-radius: 1124px;
    -o-border-radius: 1124px;
    -ms-border-radius: 1124px;
    -webkit-border-radius: 1124px;
    -moz-border-radius: 1124px;
}

.cssload-whirlpool {
    margin: -28px 0 0 -28px;
    height: 56px;
    width: 56px;
    animation: cssload-rotate 1150ms linear infinite;
    -o-animation: cssload-rotate 1150ms linear infinite;
    -ms-animation: cssload-rotate 1150ms linear infinite;
    -webkit-animation: cssload-rotate 1150ms linear infinite;
    -moz-animation: cssload-rotate 1150ms linear infinite;
}

.cssload-whirlpool::before {
    content: "";
    margin: -26px 0 0 -26px;
    height: 50px;
    width: 50px;
    animation: cssload-rotate 1150ms linear infinite;
    -o-animation: cssload-rotate 1150ms linear infinite;
    -ms-animation: cssload-rotate 1150ms linear infinite;
    -webkit-animation: cssload-rotate 1150ms linear infinite;
    -moz-animation: cssload-rotate 1150ms linear infinite;
}

.cssload-whirlpool::after {
    content: "";
    margin: -33px 0 0 -33px;
    height: 63px;
    width: 63px;
    animation: cssload-rotate 2300ms linear infinite;
    -o-animation: cssload-rotate 2300ms linear infinite;
    -ms-animation: cssload-rotate 2300ms linear infinite;
    -webkit-animation: cssload-rotate 2300ms linear infinite;
    -moz-animation: cssload-rotate 2300ms linear infinite;
}

@keyframes cssload-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@-o-keyframes cssload-rotate {
    100% {
        -o-transform: rotate(360deg);
    }
}

@-ms-keyframes cssload-rotate {
    100% {
        -ms-transform: rotate(360deg);
    }
}

@-webkit-keyframes cssload-rotate {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes cssload-rotate {
    100% {
        -moz-transform: rotate(360deg);
    }
}
/* end loader */

.responsive {
    overflow-x: auto;
}

.inline {
    display: inline;
    margin-right: 10px;
}

table tr.header {
    background: #f1f1f1;
    font-weight: bold;
}

.datetimepicker {
    border: 1px solid #ddd;
    background:#FFF;
}
.datetimepicker .header .MonthPicker,
.datetimepicker .header .YearPicker{
    background:#e8e8e8;
}
.datetimepicker .header .MonthPicker .table>tbody>tr>td,
.datetimepicker .header .YearPicker .table>tbody>tr>td{
    border-top: 1px solid rgba(0,0,0,.25);
}
.datetimepicker table{
    text-align:center;
    margin-bottom:0;
}
.datetimepicker .table.calendar>tbody>tr>td{
    border-top:none;
}
.datetimepicker table td{
    cursor:pointer;
}
.datetimepicker table td.currentDay,
.datetimepicker table td.currentMonth,
.datetimepicker table td.currentYear,
.datetimepicker table td:hover {
    background:#cc0000;
    color:#FFF;
}
.datetimepicker .header{
    border: 1px solid #ddd;
    height:37px;
}
.datetimepicker .header .YearPicker{
    left:195px;
}
.datetimepicker .header .MonthPicker,
.datetimepicker .header .YearPicker{
    position:absolute;
    -moz-transition:0.5s;
    -o-transition:0.5s;
    -webkit-transition:0.5s;
    transition:0.5s;
    height:35px;
    overflow-y:hidden;
}
.datetimepicker .header .MonthPicker:hover,
.datetimepicker .header .YearPicker:hover{
    height:220px;
    overflow-y:scroll;
}

span.glyphicon.glyphicon-chevron-down,
span.glyphicon.glyphicon-chevron-up {
    color: #adadad;
    font-size: 13px;
}

.page-header {
    border-bottom: none;
}

@media (max-width: 1024px) {
    #sidebar span.glyphicon.glyphicon-chevron-down,
    #sidebar span.glyphicon.glyphicon-chevron-up {
        top: -17px;
    }
}


